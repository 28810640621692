<template>
  <v-dialog
    style="
      z-index: 99999999999999999999999999999999999999999999;
      position: relative;
    "
    persistent
    v-model="vDialogEdit"
    :width="`${screenWidth}%`"
  >
    <v-card class="rounded-dialog">
      <!-- Componente general para cerrar los dialogs  -->
      <close-dialog-component @closeDialog="closeDialog" />
      <!-- Cotenido del titulo  -->
      <v-card-title
        class="headline justify-center pt-8"
        style="text-align: center"
      >
        {{(!bModify)?'Editar':'Detalle'}} cobro
      </v-card-title>
      <hr class="separator mb-4" />
      <!-- Contenido de los inputs  -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="concepto"
                :disabled="bModify"
                class="text-Poppins"
                label="Concepto de cobro"
                placeholder="Concepto de cobro"
                hide-details=""
                maxlength="64"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="bMenuFinal"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fecha"
                    class="text-Poppins"
                    :disabled="bModify"
                    readonly
                    label="Fecha de cobro"
                    placeholder="Seleccionar fecha de cobro"
                    hide-details=""
                    append-icon="mdi-calendar"
                    @click:clear="fecha = null"
                    persistent-hint
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="fecha"
                  no-title
                  locale="es"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="metodoPago"
                :disabled="bModify"
                class="text-Poppins"
                clearable
                label="Método de pago"
                placeholder="Seleccionar método de pago"
                :items="items"
                item-value="sPaymentMethodId"
                item-text="sName"
                hide-details=""
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <input-currency-global
                :flagModal="vDialogEdit"
                labelGlobal="Monto cobrado"
                :bDisabled="bModify"
                @changeMontoGlobal="changeMontoGlobal"
                :modelValue="monto"
                :bRefreshValue="bRefreshValueCurrency"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="registradoPor"  disabled label="Registrado por">

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="notas"
                :disabled="bModify"
                label="Descripción del cobro(opcional)"
                placeholder="Escribir nota"
                maxlength="255"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div v-if="bModify" class="content-buttons">
        <v-btn
          x-large
          @click="closeDialog()"
          color="black"
          width="175"
          outlined
          class="text-Poppins"
          style="
            height: 50px;
            font-size: 13px;
            font-weight: 600;
            border-radius: 5px;
          "
        >
          Regresar
        </v-btn>
        <v-btn
          x-large
          width="175"
          @click="bModify = !bModify"
          color="primary"
          class="text-Poppins"
          style="
            height: 50px;
            color: black;
            box-shadow: 0px 20px 40px #00000029;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 600;
            margin-left: 50px;
          "
        >
          Modificar
        </v-btn>
      </div>
      <div v-else class="content-buttons">
        <v-btn
          x-large
          color="black"
          width="175"
          outlined
          class="text-Poppins"
          @click="discardChange()"
          style="
            height: 50px;
            font-size: 13px;
            font-weight: 600;
            border-radius: 5px;
          "
        >
          Cancelar
        </v-btn>
        <v-btn
          @click="EditCobro()"
          x-large
          width="175"
          color="primary"
          class="text-Poppins"
          :loading="bLoadingBtnEdit"
          :disabled="!validateForm"
          style="
            height: 50px;
            color: black;
            box-shadow: 0px 20px 40px #00000029;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 600;
            margin-left: 50px;
          "
        >
          Guardar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseDialogComponent from "@/components/core/CloseDialog";
import InputCurrencyGlobal from "../../core/Input-Currency-Global.vue";

import moment from "moment";

export default {
  name: "CobrosDialogEdit",
  props: {
    vDialogEdit: Boolean,
    vDialogItems: Object,
  },
  data() {
    return {
      screenWidth: 0,
      cobroId: "",
      concepto: "",
      conceptoOrigin: "",
      menu: false,
      fecha: null,
      fechaOrigin: null,
      metodoPago: "",
      metodoPagoOrigin: "",
      monto: 0,
      montoOrigin: 0,
      notas: "",
      notasOrigin: "",
      registradoPor:"",
      items: [],
      flagModal: false,
      bLoadingBtnEdit: false,
      bModify: true,
      bRefreshValueCurrency: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    toogleDialogEdit: function () {
      this.$emit("toogleDialogEdit");
      this.menu = false;
      this.fecha = null;
      this.concepto = "";
      this.metodoPago = "";
      this.monto = 0;
      this.notas = "";
    },
    isNumberMoney(evt) {
      if (evt.target.value.includes(".")) {
        let sDecimal = "";
        sDecimal = evt.target.value.substring(
          evt.target.value.indexOf("."),
          evt.target.value.length
        );
        if (sDecimal.length > 2) {
          evt.preventDefault();
        }
      }
      if (evt.which === 101 || evt.which === 45) {
        evt.preventDefault();
      }
    },
    noLetters() {
      setTimeout(() => {
        const regexExp = /^((?!e)(?!E)[0-9])+$/;
        let result = regexExp.exec(this.montoContrato);
        if (result == null) {
          this.montoContrato = "";
        } else {
          this.montoContrato = this.montoContrato;
        }
      }, 100);
    },
    EditCobro() {
      this.bLoadingBtnEdit = true;
      const payload = {
          sProjectId: this.$route.params.sProyectId,
          sConcept: this.concepto,
          dQuantity: this.monto,
          tChargeDate: this.fecha,
          sPaymentMethodId: this.metodoPago,
          sDescription: this.notas,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      //post credentials and get access token from laravel backend
      db.put(`${uri}/api/v1/charges/${this.cobroId}`, payload, config)
        .then((response) => {
          this.$store.commit("refresher", true);
          this.concepto = "";
          this.monto = 0;
          this.fecha = "";
          this.metodoPago = "";

          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.bLoadingBtnEdit = false;
          this.closeDialog();
        })
        .catch((err) => {
          this.bLoadingBtnEdit = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.items = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changeMontoGlobal(value) {
      this.monto = parseFloat(value);
    },
    closeDialog: function () {
      this.bModify = true;
      this.$emit("toogleDialogEdit", {
        active: !this.vDialogEdit,
        items: {},
      });
    },
    discardChange() {
      this.bModify = true;
      this.concepto = this.conceptoOrigin;
      this.fecha = this.fechaOrigin;
      this.metodoPago = this.metodoPagoOrigin;
      this.monto = this.montoOrigin;
      this.notas = this.notasOrigin;
      this.bRefreshValueCurrency = !this.bRefreshValueCurrency;
    },
  },
  computed: {
    validateForm() {
      return (
        this.concepto !== "" &&
        this.fecha !== "" &&
        this.fecha !== null &&
        this.metodoPago !== "" &&
        this.metodoPago !== null &&
        parseFloat(this.monto)
      );
    },
  },
  watch: {
    vDialogItems: function () {
      if (this.vDialogEdit) {
        this.getMetodoPago();
        let arr = this.vDialogItems;

        this.cobroId = arr.sChargeId;
        this.concepto = arr.sConcept;
        this.conceptoOrigin = arr.sConcept;
        this.fecha = moment(arr.tChargeDate, "DD MMM YYYY").format(
          "YYYY-MM-DD"
        );
        this.fechaOrigin = moment(arr.tChargeDate, "DD MMM YYYY").format(
          "YYYY-MM-DD"
        );
        this.metodoPago = arr.sPaymentMethodId;
        this.metodoPagoOrigin = arr.sPaymentMethodId;
        this.monto = parseFloat(arr.dQuantity);
        this.montoOrigin = parseFloat(arr.dQuantity);
        this.notas = arr.sDescription;
        this.notasOrigin = arr.sDescription;
        this.registradoPor = arr.sChargeCreator
      }
    },
    vDialogEdit() {
      if (this.vDialogEdit) {
        this.flagModal = true;
      }
    },
  },
  components: {
    CloseDialogComponent,
    InputCurrencyGlobal,
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  padding-bottom: 30px;
}

.separator {
  height: 1px;
  background-color: #ffce00 !important;
  border: none;
}

.rounded-dialog {
  border-radius: 10px !important;
}
</style>