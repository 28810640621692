<template>
  <v-dialog
    v-model="openDialogExpense"
    scrollable
    persistent
    :overlay="false"
    max-width="1100px"
    transition="dialog-transition"
    style="z-index: 1"
  >
    <v-card>
      <v-card-title
        class="headline justify-center"
        style="text-align: center; position: relative"
      >
        <v-btn
          icon
          @click="close"
          style="position: absolute; right: 5px; top: 5px"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
        {{
          sAddorEditExpense ? "Modificación gasto manual" : "Nuevo gasto manual"
        }}
      </v-card-title>
      <hr style="height: 1px; background-color: #ffce00; border: none" />
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="proyecto"
              class="text-Poppins"
              item-text="sName"
              item-value="sProjectId"
              @change="getCategoriesbyProyect(proyecto)"
              :items="itemsProyecto"
              label="Proyecto"
              style="margin-bottom: -10px"
              :disabled="this.sProjectId !== ''"
            ></v-select>
            <v-select
              v-model="proveedor"
              class="text-Poppins"
              item-text="sCompanyName"
              item-value="sSupplierId"
              :items="itemsProveedores"
              label="Proveedor"
              style="margin-bottom: -10px"
              @change="changeSuppliers()"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="categoria"
              no-data-text="No hay categorias"
              class="text-Poppins"
              item-text="sCategoryName"
              item-value="sCategoryId"
              :items="itemsCategorias"
              label="Categoría"
              style="margin-bottom: -10px"
              @change="changeCategory()"
              :disabled="bBlockedCategory"
            ></v-select>
            <v-select
              v-model="metodoPago"
              class="text-Poppins"
              item-text="sName"
              item-value="sPaymentMethodId"
              :items="itemsMetodoPago"
              label="Método de Pago"
              style="margin-bottom: -10px"
              @change="changePaymentMethod()"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="comentarios"
              rows="3"
              class="texts-inputs"
              label="Notas"
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-card style="border-radius: 10px">
              <v-simple-table>
                <template v-slot:default>
                  <thead v-if="!bLoading">
                    <tr>
                      <th
                        class="text-center"
                        style="
                          width: 170px !important;
                          min-width: 170px !important;
                          max-width: 170px !important;
                        "
                      >
                        Concepto
                      </th>
                      <th
                        class="text-center"
                        style="
                          width: 100px !important;
                          min-width: 100px !important;
                          max-width: 100px !important;
                        "
                      >
                        Cantidad
                      </th>
                      <th
                        class="text-center"
                        style="
                          width: 140px !important;
                          min-width: 140px !important;
                          max-width: 140px !important;
                        "
                      >
                        Unidad de medida
                      </th>
                      <th
                        class="text-center"
                        style="
                          width: 130px !important;
                          min-width: 130px !important;
                          max-width: 130px !important;
                        "
                      >
                        Precio unitario
                      </th>
                      <th
                        class="text-center"
                        style="
                          width: 170px !important;
                          min-width: 170px !important;
                          max-width: 170px !important;
                        "
                      >
                        Fecha
                      </th>
                      <th
                        class="text-center"
                        v-if="!sAddorEditExpense"
                        style="
                          width: 30px !important;
                          min-width: 30px !important;
                          max-width: 30px !important;
                        "
                      >
                        Acción
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!bLoading">
                    <tr v-for="(item, index) in itemsMateriales" :key="index">
                      <td>
                        <input
                          v-model="item.sName"
                          class="input-table-concepto"
                        />
                      </td>
                      <td>
                        <input
                          v-model="item.dQuantity"
                          class="input-table-concepto"
                          @keypress="isNumber($event)"
                          @change="notCero(cantidadMaterial)"
                        />
                      </td>
                      <td>
                        <input
                          v-model="item.sMeasureUnitName"
                          :key="index"
                          class="input-table-concepto"
                        />
                      </td>
                      <td>
                        <input
                          v-model="item.dUnitPrice"
                          :key="index"
                          @keypress="isNumber($event)"
                          class="input-table-concepto"
                        />
                      </td>
                      <td @click="item.bMenuDate = true">
                        <v-menu
                          ref="item.bMenuDate"
                          v-model="item.bMenuDate"
                          :close-on-content-click="false"
                          :return-value="expenseDate"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              class="input-nueva-soli"
                              dense
                              v-model="item.tExpenseDate"
                              outlined
                              hide-details
                              color="primary"
                              append-icon="mdi-calendar"
                              @click:clear="item.tExpenseDate = ''"
                              placeholder="Fecha"
                              persistent-hint
                              v-on="on"
                              style="font-family: 'Poppins', sans-serif; !important"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="yellow darken-2"
                            header-color="primary"
                            v-model="item.tExpenseDate"
                            no-title
                            locale="es-MX"
                            @input="item.bMenuDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                      <td v-if="!sAddorEditExpense" class="text-center">
                        <v-icon color="red" @click="deleteItemMaterial(index)"
                          >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                  <div v-show="bLoading">
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                    <div class="content-text-loading">
                      <p class="text-loading">Cargando... Espere por favor</p>
                    </div>
                  </div>
                  <br />
                  <tfoot v-if="!sAddorEditExpense">
                    <tr>
                      <td>
                        <v-text-field
                          v-model="nombreMaterial"
                          class="input-nueva-soli"
                          outlined
                          dense
                          placeholder="Concepto"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="cantidadMaterial"
                          class="input-nueva-soli"
                          outlined
                          dense
                          placeholder="Cantidad"
                          @keypress="isNumber($event)"
                          @change="notCero(cantidadMaterial)"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="unidadMedidaMaterial"
                          class="input-nueva-soli"
                          outlined
                          dense
                          placeholder="Unidad de medida"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="precioUnitario"
                          @keypress="isNumber($event)"
                          class="input-nueva-soli"
                          outlined
                          dense
                          placeholder="Precio unitario"
                        ></v-text-field>
                      </td>
                      <td @click="bMenuDate = true">
                        <v-menu
                          ref="bMenuDate"
                          v-model="bMenuDate"
                          :close-on-content-click="false"
                          :return-value="expenseDate"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              class="input-nueva-soli"
                              dense
                              v-model="expenseDate"
                              outlined
                              hide-details
                              color="primary"
                              append-icon="mdi-calendar"
                              @click:clear="expenseDate = ''"
                              placeholder="Fecha"
                              persistent-hint
                              v-on="on"
                              style="font-family: 'Poppins', sans-serif; !important;margin-top:-25px"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="yellow darken-2"
                            header-color="primary"
                            v-model="expenseDate"
                            no-title
                            locale="es-MX"
                            @input="bMenuDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                      <td v-if="!sAddorEditExpense" class="text-center">
                        <v-btn
                          style="
                            background-color: #ffce00;
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-weight: 600;
                            border-radius: 10px;
                            width: 10px;
                            margin-top: -25px;
                          "
                          :disabled="!validateAnadir"
                          @click="setMaterial"
                          >Añadir</v-btn
                        >
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <hr
                  style="
                    width: 97%;
                    border: 1px solid #fafafa;
                    margin-top: 50px;
                  "
                />
              </div>
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  padding-right: 20px;
                "
              >
                <p
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                  "
                >
                  SUBTOTAL
                  <span style="color: #63e3b2; margin-left: 10px"
                    >${{ sumatoriaMateriales() }} MXN</span
                  >
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- BUTONS ACTIONS -->
        <v-row>
          <v-col cols="12" sm="12">
            <div class="text-right mt-10">
              <v-btn
                @click="close"
                x-large
                color="black"
                width="175"
                outlined
                style="
                  height: 40px;
                  font-size: 13px;
                  font-weight: 600;
                  border-radius: 5px;
                  margin-right: 35px;
                "
                >Cancelar
              </v-btn>
              <v-btn
                v-if="sCustomExpenseId === ''"
                x-large
                width="175"
                color="primary"
                style="
                  height: 40px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                "
                :disabled="!validateForm"
                :loading="bLoadingBtnSave"
                @click="createNewExpense()"
                >Registrar
              </v-btn>

              <v-btn
                v-else
                x-large
                width="175"
                color="primary"
                style="
                  height: 40px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                "
                :disabled="!validateForm"
                :loading="bLoadingBtnSave"
                @click="editExpense()"
                >Modificar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import moment from "moment";
import moment from "moment-timezone";

export default {
  name: "DialogExpense",
  props: {
    openDialogExpense: {
      Type: Boolean,
      default: false,
    },
    typeBloqued: {
      Type: String, //1: Bloqued project, 2 Bloqued project y category
    },
    sProjectId: {
      Type: Object,
      default: "",
    },
    sCategoryId: {
      Type: String,
      default: "",
    },
    sCustomExpenseId: {
      Type: String,
      default: "",
    },
    bBlockedCategory: {
      Type: Boolean,
      default: false,
    },
    sAddorEditExpense: {
      Type: Boolean,
      default: false,
    },
    goToDetailProyect: {
      Type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemsProyecto: [],
      itemsCategorias: [],
      itemsProveedores: [],
      itemsMetodoPago: [],
      itemsUnits: [],
      itemsMateriales: [],
      proyecto: "",
      categoria: "",
      proveedor: null,
      metodoPago: "",
      comentarios: "",
      nombreMaterial: "",
      cantidadMaterial: "",
      unidadMedidaMaterial: "",
      unidadMedidaMaterialText: "",
      precioUnitario: "",
      expenseDate: "",
      lodingButtonBorrador: false,
      bLoadingBtnSave: false,
      filelist: [], // Store our uploaded files
      bMenuDate: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.allData();
  },
  methods: {
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 1 && evt.which < 46) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(cantidadMaterial) {
      if (cantidadMaterial !== "") {
        if (cantidadMaterial <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.cantidadMaterial = "";
        }
      }
    },
    close() {
      this.$emit("close");
      this.bLoading = false;
      this.proyecto = "";
      this.categoria = "";
      this.proveedor = "";
      this.metodoPago = "";
      this.comentarios = "";
      this.comentarios = "";
      this.itemsMateriales = [];
      (this.nombreMaterial = ""),
        (this.cantidadMaterial = ""),
        (this.unidadMedidaMaterial = ""),
        (this.unidadMedidaMaterialText = ""),
        (this.precioUnitario = ""),
        (this.expenseDate = ""),
        (this.filelist = []);
    },
    // get proyectos
    getProyectos() {
      db.get(`${uri}/api/v1/projects/?iPageNumber=1&isUser=true`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsProyecto = resp.data.projects;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get categories by proyect
    getCategoriesbyProyect(idProyecto) {
      this.itemsCategorias = [];
      this.categoria = "";
      db.get(`${uri}/api/v1/projects/${idProyecto}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsCategorias = resp.data.project.Categories;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get proyectos
    getProveedores() {
      db.get(`${uri}/api/v1/supplier/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsProveedores = resp.data.suppliers;
          this.itemsProveedores.push({
            sCompanyEconomicActivity: "",
            sCompanyName: "Sin proveedor",
            sEmail: "",
            sFullName: "",
            sRFC: "",
            sSupplierId: null,
          });
          this.itemsProveedores.unshift({
            sCompanyEconomicActivity: "",
            sCompanyName: "Sin proveedor",
            sEmail: "",
            sFullName: "",
            sRFC: "",
            sSupplierId: null,
          });
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get units
    getUnits() {
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsUnits = resp.data.units;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.itemsUnits.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    getExpensebyId() {
      this.bLoading = true;

      db.get(
        `${uri}/api/v1/custom_expenses/${this.sCustomExpenseId}?formatDate=false`,
        {
          params: {
            // sCustomExpenseId: this.sCustomExpenseId,
            // dateFormat: false
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          // this.itemsProveedores = resp.data.customExpense;
          this.categoria = resp.data.customExpense.sCategoryId;
          this.proveedor = resp.data.customExpense.sSupplierId;
          this.metodoPago = resp.data.customExpense.sPaymentMethodId;
          this.comentarios = resp.data.customExpense.sComments;
          this.itemsMateriales = [
            {
              sName: resp.data.customExpense.sName,
              dQuantity: resp.data.customExpense.dQuantity,
              sMeasureUnitName: resp.data.customExpense.sMeasureUnitName,
              dUnitPrice: resp.data.customExpense.dUnitPrice,
              tExpenseDate: resp.data.customExpense.tExpenseDate.substr(0, 10),
            },
          ];
          this.bLoading = false;

          // this.itemsMateriales =
          //   resp.data.customExpense.CustomExpenseMaterials.map((e) => {
          //     return {
          //       sName: e.sName,
          //       dQuantity: e.dQuantity,
          //       sMeasureUnitId: e.sMeasureUnitName,
          //       sMeasureUnitName: e.sMeasureUnitName,
          //       unidadMedidaMaterial: e.sMeasureUnitName,
          //       dUnitPrice: e.dUnitPrice,
          //       tExpenseDate: e.tExpenseDate.substr(0, 10),
          //       bMenuDate: false,
          //     };
          //   });
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    allData() {
      this.expenseDate = moment().format().substring(0, 10);
      this.getProyectos();
      if (this.sProjectId !== "") {
        this.proyecto = this.sProjectId;
        this.getCategoriesbyProyect(this.sProjectId);
      }
      if (this.sCategoryId !== "") {
        this.categoria = this.sCategoryId;
      }

      this.getProveedores();
      this.getMetodoPago();
      if (this.sCustomExpenseId !== "") {
        this.getExpensebyId();
      }
      this.getUnits();
      // this.loadMaterials();
    },
    loadMaterials() {
      if (this.resquestMaterials !== null) {
        var arr = this.resquestMaterials.map((e) => {
          return {
            ...e,
            sName: e.sName,
            dQuantity: e.dQuantity,
            sMeasureUnitId: e.sMeasureUnitId,
            sMeasureUnitName: e.sMeasureUnitName,
            unidadMedidaMaterial: e.sMeasureUnitId,
            dUnitPrice: e.dUnitPrice,
            tExpenseDate: e.tExpenseDate,
            // tExpenseDate : e.tExpenseDate
          };
        });

        arr.forEach((element) => {
          this.itemsMateriales.push(element);
        });
      }
    },
    setMaterial() {
      const arr = {
        sName: this.nombreMaterial,
        dQuantity: this.cantidadMaterial,
        sMeasureUnitId: this.unidadMedidaMaterial,
        sMeasureUnitName: this.unidadMedidaMaterial,
        unidadMedidaMaterial: this.unidadMedidaMaterial,
        dUnitPrice: this.precioUnitario,
        tExpenseDate: this.expenseDate,
      };
      this.itemsMateriales.push(arr);
      this.clearItemsMateriales();
    },
    clearItemsMateriales() {
      this.nombreMaterial = "";
      this.cantidadMaterial = "";
      this.unidadMedidaMaterial = "";
      this.unidadMedidaMaterialText = "";
      this.precioUnitario = "";
      this.expenseDate = moment().format().substring(0, 10);
    },
    deleteItemMaterial(e) {
      this.itemsMateriales.splice(e, 1);
    },
    formatDDMMMYYY(date) {
      var new_date = moment(date, "YYYY-MM-DD").locale("es");
      var format_date = moment(new_date).format("DD/MMM/YYYY").replace(".", "");
      return (
        format_date.substr(0, 3) +
        format_date.charAt(3).toUpperCase() +
        format_date.substr(4)
      );
    },
    createNewExpense() {
      var idproyecto = "";
      this.bLoadingBtnSave = true;
      if (this.proyecto == undefined) {
        idproyecto = this.proyecto;
      } else {
        idproyecto = this.proyecto;
      }

      if (this.itemsMateriales.length !== 0) {
        const payload = {
            sProjectId: idproyecto,
            sCategoryId: this.categoria,
            sSupplierId: this.proveedor == null ? undefined : this.proveedor,
            sPaymentMethodId: this.metodoPago,
            sComments: this.comentarios,
            aCustomExpenseMaterials: this.itemsMateriales.map((se) => {
              return {
                sName: se.sName,
                dQuantity: se.dQuantity,
                dUnitPrice: se.dUnitPrice,
                sMeasureUnitName: se.sMeasureUnitName,
                tExpenseDate: new Date(se.tExpenseDate),
              };
            }),
          },
          config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              contentType: "application/x-www-form-urlencoded;charset=utf-8",
            },
          };
        //post credentials and get access token from laravel backend
        db.post(`${uri}/api/v1/custom_expenses`, payload, config)
          .then((response) => {
            this.itemsMateriales = [];
            // this.proyecto = "";
            this.proveedor = "";
            this.metodoPago = "";
            this.comentarios = "";
            const alert = {
              color: "green",
              text: response.data.message,
            };
            this.bLoadingBtnSave = false;

            this.$store.commit("toggle_alert", alert);
            if (this.goToDetailProyect) {
              this.$router.push({
                name: "ProyectsDetail",
                params: {
                  sProyectId: this.proyecto,
                },
              });
            } else {
              this.close();
              this.$store.commit("refresher", true);
            }
          })
          .catch((err) => {
            this.bLoadingBtnSave = false;

            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        const alert = {
          color: "red",
          text: "El concepto debe de tener al menos un concepto.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    editExpense() {
      this.bLoadingBtnSave = true;
      if (this.itemsMateriales.length !== 0) {
        const payload = {
            sComments: this.comentarios,
            sName: this.itemsMateriales[0].sName,
            dQuantity: this.itemsMateriales[0].dQuantity,
            dUnitPrice: this.itemsMateriales[0].dUnitPrice,
            sMeasureUnitName: this.itemsMateriales[0].sMeasureUnitName,
            tExpenseDate: this.itemsMateriales[0].tExpenseDate,
          },
          config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              contentType: "application/x-www-form-urlencoded;charset=utf-8",
            },
          };
        //post credentials and get access token from laravel backend

        db.put(
          `${uri}/api/v1/custom_expenses/${this.sCustomExpenseId}`,
          payload,
          config
        )
          .then((response) => {
            this.comentarios = "";
            this.itemsMateriales = [];
            const alert = {
              color: "green",
              text: response.data.message,
            };
            this.close();
            this.$store.commit("toggle_alert", alert);
            this.$store.commit("refresher", true);
            this.bLoadingBtnSave = false;
          })
          .catch((err) => {
            this.bLoadingBtnSave = false;

            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        const alert = {
          color: "red",
          text: "El gasto debe de tener al menos un concepto.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    changeCategory() {
      if (this.sAddorEditExpense !== false) {
        if (this.sCategoryId !== "") {
          const param = {
            sCategoryId: this.categoria,
          };
          db.put(
            `${uri}/api/v1/custom_expenses/${this.sCustomExpenseId}/category`,
            param,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("user-tk"),
                "content-type": "application/json",
              },
            }
          )
            .then((resp) => {
              const alert = {
                color: "green",
                text: resp.data.message,
              };
              this.close();
              this.$store.commit("refresher", true);
              this.$store.commit("toggle_alert", alert);
            })
            .catch((err) => {
              const alert = {
                color: "red",
                text: err.response.data.message,
              };
              this.$store.commit("toggle_alert", alert);
            });
        }
      }
    },
    changePaymentMethod() {
      if (this.sCustomExpenseId !== "") {
        const param = {
          sPaymentMethodId: this.metodoPago,
        };
        db.put(
          `${uri}/api/v1/custom_expenses/${this.sCustomExpenseId}/payment`,
          param,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              "content-type": "application/json",
            },
          }
        )
          .then((resp) => {
            const alert = {
              color: "green",
              text: resp.data.message,
            };
            // this.close();
            this.$store.commit("refresher", true);
            this.$store.commit("toggle_alert", alert);
          })
          .catch((err) => {
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },
    changeSuppliers() {
      if (this.sAddorEditExpense !== false) {
        if (this.sCustomExpenseId !== "") {
          const param = {
            sSupplierId: this.proveedor !== "" ? this.proveedor : null,
          };
          db.put(
            `${uri}/api/v1/custom_expenses/${this.sCustomExpenseId}/supplier`,
            param,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("user-tk"),
                "content-type": "application/json",
              },
            }
          )
            .then((resp) => {
              const alert = {
                color: "green",
                text: resp.data.message,
              };
              this.$store.commit("refresher", true);
              this.$store.commit("toggle_alert", alert);
            })
            .catch((err) => {
              const alert = {
                color: "red",
                text: err.response.data.message,
              };
              this.$store.commit("toggle_alert", alert);
            });
        }
      }
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsMateriales.length; i++) {
        const element = this.itemsMateriales[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
  },
  computed: {
    validateForm() {
      if (this.sAddorEditExpense) {
        return (
          this.proyecto.sName !== "" &&
          this.categoria !== "" &&
          this.metodoPago !== "" &&
          this.itemsMateriales[0].sName !== "" &&
          this.itemsMateriales[0].dQuantity !== "" &&
          this.itemsMateriales[0].sMeasureUnitName !== "" &&
          this.itemsMateriales[0].dUnitPrice !== "" &&
          this.itemsMateriales[0].tExpenseDate !== ""
        );
      } else {
        return (
          this.proyecto.sName !== "" &&
          this.categoria !== "" &&
          this.metodoPago !== "" &&
          this.itemsMateriales.length >= 1
        );
      }
    },
    validateAnadir() {
      return (
        this.nombreMaterial.trim() !== "" &&
        this.cantidadMaterial !== "" &&
        this.unidadMedidaMaterial.trim() !== "" &&
        this.expenseDate !== "" &&
        this.precioUnitario !== ""
      );
    },
  },
  watch: {
    openDialogExpense: function () {
      if (this.openDialogExpense) {
        this.allData();
      }
    },
  },
};
</script>



<style>
.formAddConceptExpense .v-text-field__details {
  display: none;
}
</style>
<style
  scoped>
[v-cloak] {
  display: none;
}
.texts-inputs {
  margin-bottom: -10px;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}

.button-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;
  color: #737373;
  padding: 5px 25px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: 2px solid #737373;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

#assetsFieldHandle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.input-table-concepto {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
  width: 100% !important;
}

.input-table-concepto:focus {
  outline: 0;
}
</style>