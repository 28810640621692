<template>
  <v-btn
    @click="closeDialog"
    class="close"
    dark
    fab
    small
    icon
    color="black"
    rounded
  >
    <v-icon> mdi-close </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CloseDialogComponent",
  methods: {
    closeDialog: function () {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>