<template>
  <div>
    <v-card>
      <v-card-text class="text-end pa-1">
        <v-btn
          @click="toogleDialogAdd"
          class="text-Poppins"
          color="primary"
          style="
            color: black;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: 600;
          "
        >
          Registrar cobro
        </v-btn>
      </v-card-text>
      <v-card-text class="pa-1">
        <div style="margin-top: 15px">
          <v-card style="padding: 15px; border-radius: 20px">
            <v-simple-table fixed-header height="320px">
              <template v-slot:default>
                <thead v-if="!bLoading">
                  <tr>
                    <th
                      class="text-left"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Fecha
                    </th>
                    <th
                      class="text-left"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Concepto
                    </th>
                    <th
                      class="text-left"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Método de pago
                    </th>
                    <th
                      class="text-left"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Monto
                    </th>

                    <th
                      class="text-left"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Registrado por
                    </th>
                    <th
                      class="text-right"
                      style="border-bottom: 3px solid #e4e4e4"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!bLoading">
                  <!-- Agregar el v-for cuando ya esten los datos
                            cargados de los cobros  -->
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-left">
                      {{ item.tChargeDate }}
                    </td>
                    <td class="text-left">
                      {{ item.sConcept }}
                    </td>
                    <td class="text-left">
                      {{ item.sPaymentMethod }}
                    </td>
                    <td class="text-left">
                      {{ mask(item.dQuantity) }}
                    </td>

                    <td class="text-left">
                      {{ item.sChargeCreator }}
                    </td>
                    <td class="text-right">
                      <!-- Agregar click al botones -->
                      <v-btn @click="toogleDialogEdit(item)" color="black" icon>
                        <v-icon> mdi-eye </v-icon>
                      </v-btn>
                      <!-- Agregar click al boton  -->
                      <v-btn icon @click="deleteCobro(item)">
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-show="bEmptyData">
                    <td colspan="6">
                      <div
                        style="
                          width: 100%;
                          height: 200px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <p
                          style="
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                          "
                        >
                          Aún no se han agregado cobros <br />
                          a este proyecto.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div v-show="bLoading">
                  <v-progress-linear
                    indeterminate
                    color="yellow darken-2"
                  ></v-progress-linear>
                  <div class="content-text-loading">
                    <p class="text-loading">Cargando... Espere por favor</p>
                  </div>
                </div>
              </template>
            </v-simple-table>
            <hr style="height: 1px; background-color: #efefef; border: none" />
            <div class="footer-table">
              <div style="margin-right: 30px">
                <p>
                  Filas por página:
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="footer-table-dropdown"
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iItemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="changePage(5)">
                        <v-list-item-title>5</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changePage(10)">
                        <v-list-item-title>10</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changePage(25)">
                        <v-list-item-title>25</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changePage(50)">
                        <v-list-item-title>50</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </p>
              </div>
              <div style="margin-right: 15px">
                <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
              </div>
              <div>
                <v-btn
                  elevation="0"
                  class="footer-table-buttons"
                  @click="backPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  class="footer-table-buttons"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <cobros-dialog-add
      :vDialogAdd="vDialogAdd"
      @toogleDialogAdd="toogleDialogAdd"
    />

    <cobros-dialog-edit
      :vDialogEdit="vDialogEdit"
      :vDialogItems="vDialogItems"
      @toogleDialogEdit="toogleDialogEdit"
    />
  </div>
</template>

<script>
import LoginVue from "../../../views/Login.vue";
import CobrosDialogAdd from "./CobrosDialogAdd.vue";
import CobrosDialogEdit from "./CobrosDialogEdit.vue";

export default {
  name: "CobrosContent",

  data() {
    return {
      items: [],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      vDialogAdd: false,
      vDialogDetail: false,
      vDialogEdit: false,
      vDialogItems: {},
      bLoading: true,
      bEmptyData: false,
    };
  },
  beforeMount() {
    this.getCobros();
  },
  methods: {
    getCobros: function () {
      db.get(`${uri}/api/v1/charges/${this.$route.params.sProyectId}`, {
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.totalItems = resp.data.iTotal;
          this.items = resp.data.charges;
          // .map((e)=>{
          //   return{
          //     ...e,
          //     created_at: e.created_at.substr(0,10)
          //   }
          // });
          this.bLoading = false;
          this.bEmptyData = this.items.length === 0;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });

      // setTimeout(() => {
      //   this.items = [
      //     {
      //       id: "Lorem ipsum",
      //       fecha: "1999-01-01",
      //       metodoPago: "Lorem ipsum",
      //       monto: "Lorem ipsum",
      //       concepto: "Lorem ipsum",
      //       registradoPor: "Lorem ipsum",
      //       notas: "Lorem ipsum",
      //     },
      //   ];
      //   this.bLoading = false;
      //   this.bEmptyData = this.items.length === 0;
      // }, 2000);
    },
    deleteCobro(item) {
      this.$store.commit("setDeleteItems", {
        id: "",
        route: "/api/v1/charges/" + item.sChargeId,
        unit: " cobro.",
        idItem: 0,
        msgDelete: "Eliminar un cobro es una acción irreversible",
        msgQuestionDelete: "¿Desea continuar?",
      });
    },
    toogleDialogAdd: function () {
      this.vDialogAdd = !this.vDialogAdd;
    },
    toogleDialogDetail: function (item) {
      this.vDialogItems = item;
      this.vDialogDetail = !this.vDialogDetail;
    },
    toogleDialogEdit(item) {
      this.vDialogItems = item;
      this.vDialogEdit = !this.vDialogEdit;
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    changePage: function (e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getCobros();
    },
    backPage: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bTabProyectDetail() {
      return this.$store.state.bTabProyectDetail;
    },
  },
  watch: {
    refreshTable: function () {
      this.getCobros();
    },
    iCurrentPage: function () {
      this.getCobros();
    },
    bTabProyectDetail() {
      if (this.bTabProyectDetail === 2) {
        this.getCobros();
      }
    },
  },
  components: {
    CobrosDialogAdd,
    CobrosDialogEdit,
  },
};
</script>