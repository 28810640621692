<template>
  <div style="width: 100%">
    <!-- Start card for float effect -->
    <v-card class="TableWrapper scroll-horizontal-table">
      <v-simple-table fixed-header height="490px" :loading="true">
        <template v-slot:default>
          <thead v-if="!bLoading">
            <tr>
              <th
                class="text-left"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 200px;
                  min-width: 200px;
                  max-width: 200px;
                "
              >
                Proyecto
              </th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 350px;
                  min-width: 350px;
                  max-width: 350px;
                "
              >
                Gasto / presupuesto
              </th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 30px;
                  min-width: 30px;
                  max-width: 30px;
                "
              ></th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 350px;
                  min-width: 350px;
                  max-width: 350px;
                "
              >
                Cobros / Monto del contrato
              </th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 30px;
                  min-width: 30px;
                  max-width: 30px;
                "
              ></th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 100px;
                  min-width: 100px;
                  max-width: 100px;
                "
              >
                Estatus
              </th>
              <th
                class="text-center"
                style="
                  border-bottom: 3px solid #e4e4e4;
                  width: 100px;
                  min-width: 100px;
                  max-width: 100px;
                "
              >
                Fecha de creación
              </th>
              <th
                class="text-center"
                style="border-bottom: 3px solid #e4e4e4; width: 10%"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-left">{{ item.sName }}</td>
              <td class="text-left" style="white-space: nowrap !important">
                <v-progress-linear
                  @click="viewDetails(item.sProjectId)"
                  :value="item.percentProyect"
                  buffer-value="100"
                  :color="item.colorProyect"
                  style="
                    height: 20px;
                    border-radius: 40px;
                    white-space: nowrap;
                    margin-top: 0px;
                    cursor: pointer;
                  "
                >
                  ${{ mask(item.dSpent) }} MXN / ${{ mask(item.dBudget) }} MXN
                </v-progress-linear>
              </td>
              <td class="text-center" style="white-space: nowrap !important">
                <p class="mt-0 ml-2 mb-0">{{ item.percentProyect }} %</p>
              </td>
              <td class="text-left" style="white-space: nowrap !important">
                <v-progress-linear
                  @click="viewDetails(item.sProjectId)"
                  :value="item.percentCharged"
                  buffer-value="100"
                  :color="item.colorCharged"
                  style="
                    height: 20px;
                    border-radius: 40px;
                    white-space: nowrap;
                    margin-top: 0px;
                    cursor: pointer;
                  "
                >
                  ${{ mask(item.dAmountCharged) }} MXN / ${{
                    mask(item.dContractAmount)
                  }}
                  MXN
                </v-progress-linear>
              </td>
              <td class="text-center" style="white-space: nowrap !important">
                <p class="mt-0 ml-2 mb-0">{{ item.percentCharged }} %</p>
              </td>
              <td
                class="text-center"
                style="
                  color: #ff5757;
                  font-weight: bold;
                  white-space: nowrap !important;
                "
                v-if="item.ProjectStatusRecords.sName == 'Detenido'"
              >
                {{ item.ProjectStatusRecords.sName }}
              </td>
              <td
                class="text-center"
                style="color: #ffc465; font-weight: bold"
                v-else-if="item.ProjectStatusRecords.sName == 'En proceso'"
              >
                {{ item.ProjectStatusRecords.sName }}
              </td>
              <td
                class="text-center"
                style="color: #41c794; font-weight: bold"
                v-else
              >
                {{ item.ProjectStatusRecords.sName }}
              </td>
              <td class="text-center">{{ item.tCreated }}</td>
              <td class="text-center" style="white-space: nowrap">
                <div>
                  <v-btn
                    v-if="bExpense"
                    dark
                    small
                    style="background-color: grey"
                    class="mr-1"
                    @click="viewDialogExpense(item.sProjectId)"
                    icon
                  >
                    <v-icon size="20"> mdi-currency-usd </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="black"
                    @click="viewDetails(item.sProjectId)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="
                      aAllows.includes('Projects') ||
                      aAllows.includes('Administrator')
                    "
                    icon
                    color="#FF5757"
                    @click="deleteProjects(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
      <div class="footer-table">
        <div style="margin-right: 30px">
          <p>
            Filas por página:
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-table-dropdown"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iItemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeTableContacts(10)">
                  <v-list-item-title>10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(25)">
                  <v-list-item-title>25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(50)">
                  <v-list-item-title>50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(100)">
                  <v-list-item-title>100</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
        <div style="margin-right: 15px">
          <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
        </div>
        <div>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="backPageContacts"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="nextPageContacts"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <!-- End card for float effect -->
    <!-- <ViewDetails
      @close="openDialogDetails = false"
      :openDialogDetails="openDialogDetails"
      :itemDetails="itemDetails"
    ></ViewDetails> -->
    <view-dialog-expense
      @close="openDialogExpense = false"
      :openDialogExpense="openDialogExpense"
      :typeBloqued="btypeBloqued"
      :sProjectId="sProjectId"
      :goToDetailProyect="goToDetailProyect"
    />
  </div>
</template>
<script>
import ViewDetails from "@/components/views/Proyects/Details";
import ViewDialogExpense from "@/components/views/Proyects/DialogExpense";

export default {
  name: "ProjectComponent",
  data() {
    return {
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,
      // store db items (Pickers)
      mainstatus: "En proceso",
      Estatus: ["En proceso", "Detenido", "Terminado"],
      items: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      openDialogDetails: false,
      openDialogExpense: false,
      itemDetails: {},
      openDialogDelete: false,
      itemDelete: {},
      permisionUser: localStorage.getItem("permisionUser"),
      aAllows: this.$store.state.aAllows.split(","),
      goToDetailProyect: true,
      sProjectId: "",
      btypeBloqued: 1,
      //Barra Porcentual//
      percent: 0,
      presupuestoInicial: "",
      presupuestoFinal: "",
    };
  },
  mounted() {
    this.getItems();
  },
  components: {
    ViewDetails,
    ViewDialogExpense,
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    sArea() {
      return this.$store.state.sArea;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    iProjectFilter() {
      return this.$store.state.iProjectFilter;
    },
    bExpense() {
      return !this.$store.state.permissions.includes("Administrator")
        ? this.$store.state.permissions.includes("ExpenseManagement")
        : true;
    },
  },
  destroyed() {
    this.$store.commit("setIProjectFilter", 1);
  },
  watch: {
    sArea: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sStatus: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    iCurrentPage: function () {
      this.getItems();
    },
    refreshTable: function () {
      this.getItems();
    },
    iProjectFilter() {
      this.iCurrentPage = 1;
      this.getItems();
    },
  },
  methods: {
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    viewDetails(idProject) {
      // this.openDialogDetails = true;
      // this.$router.push("/admin/proyect/" + idProject);
      this.$router.push({
        name: "ProyectsDetail",
        params: {
          sProyectId: idProject,
        },
      });

      // this.itemDetails = e;
    },
    viewDialogExpense(sProjectId) {
      this.openDialogExpense = true;
      this.sProjectId = sProjectId;
    },
    deleteProjects(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sProjectId,
        route: "/api/v1/projects/" + item.sProjectId,
        unit: " proyectos.",
        idItem: 1,
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeTableContacts(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems();
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    // get the items from api
    getItems() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/projects`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: this.iCurrentPage,
          sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          sArea: this.sArea,
          sProjectStatusId: this.sStatus,
          iProjectFilter: this.iProjectFilter,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.totalItems = resp.data.iTotal;
          // this.items = resp.data.projects;
          this.items = resp.data.projects.map((e) => {
            return {
              ...e,
              percentProyect: this.resultadoBarraLineal(e.dBudget, e.dSpent),
              colorProyect: this.colorGP(
                this.resultadoBarraLineal(e.dBudget, e.dSpent),
                this.resultadoBarraLineal(e.dContractAmount, e.dAmountCharged)
              ),
              percentCharged: this.resultadoBarraLineal(
                e.dContractAmount,
                e.dAmountCharged
              ),
              colorCharged: this.colorCC(
                this.resultadoBarraLineal(e.dBudget, e.dSpent),
                this.resultadoBarraLineal(e.dContractAmount, e.dAmountCharged)
              ),
              colorGeneral: e.colorCharged,
            };
          });
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    colorPercent(percent) {
      percent = Number(percent);

      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },
    colorGP(GP, CC) {
   
      let indicador = Number(GP);
      if (indicador <= 25.99) {
        return "green";
      } else if (indicador >= 26.0 && indicador <= 50.99) {
        return "yellow";
      } else if (indicador >= 51.0 && indicador <= 75.99) {
        return "orange";
      } else if (indicador >= 76.0) {
        return "red";
      } else {
        return "grey";
      }

    },
    colorCC(GP, CC) {
      let indicador = Number(CC);
      if (indicador <= 25.99) {
        return "red";
      } else if (indicador >= 26.0 && indicador <= 50.99) {
        return "orange";
      } else if (indicador >= 51.0 && indicador <= 75.99) {
        return "yellow";
      } else if (indicador >= 76.0) {
        return "green";
      } else {
        return "grey";
      }
    },

    resultadoBarraLineal(presupuestoInicial, presupuestoFinal) {
      const total = Number(presupuestoInicial),
        montoCalculado = Number(presupuestoFinal);
      var res = 0;
      if (total > 0 && montoCalculado > 0) {
        res = parseFloat((montoCalculado * 100) / total).toFixed(2);
      }
      return res;
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>

<style >
.scroll-horizontal-table {
  /* overflow: auto; */
  /* overflow-x: scroll !important;
  width: 100% !important; */
}

.tableselector.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: thin 0 0 0;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
</style>

<style scoped>
.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: red;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: dashed;
  border-width: thin 0 0 0;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 0%;
}

.select_list_labels {
  color: red;
  margin: auto;
  font-size: 18px;
  text-align: left;
}

.form_labelsStop {
  color: #ff5757;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.form_labelsFinished {
  color: #00b570;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.form_labels {
  color: #ff9d00;
  font-size: 13px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.v-list-item--link::before {
  background-color: red;
}

.v-list.v-list-item--active {
  background-color: red !important;
}
.v-list.v-list-item--active .v-list-item__title {
  color: red !important;
}

.actionsIcon {
  font-size: 20px !important;
}
.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>