<template>
  <v-dialog
    style="
      z-index: 99999999999999999999999999999999999999999999;
      position: relative;
    "
    persistent
    v-model="vDialogAdd"
    :width="`${screenWidth}%`"
  >
    <v-card class="rounded-dialog">
      <!-- Componente general para cerrar los dialogs  -->
      <close-dialog-component @closeDialog="closeDialog" />
      <!-- Cotenido del titulo  -->
      <v-card-title
        class="headline justify-center pt-8"
        style="text-align: center"
      >
        Registrar cobro
      </v-card-title>
      <hr class="separator mb-4" />
      <!-- Contenido de los inputs  -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="concepto"
                class="text-Poppins"
                label="Concepto de cobro"
                placeholder="Concepto de cobro"
                hide-details=""
                maxlength="64"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="bMenuFinal"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fecha"
                    readonly
                    class="text-Poppins"
                    label="Fecha de cobro"
                    placeholder="Seleccionar fecha de cobro"
                    hide-details=""
                    append-icon="mdi-calendar"
                    @click:clear="fecha = null"
                    persistent-hint
                    v-on="on"
                    @click:append="menu = !menu"
                    clearable
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="fecha"
                  no-title
                  locale="es"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="metodoPago"
                class="text-Poppins"
                label="Método de pago"
                placeholder="Seleccionar método de pago"
                :items="items"
                item-value="sPaymentMethodId"
                item-text="sName"
                hide-details=""
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <!-- <v-text-field
                v-model="monto"
                class="text-Poppins"
                label="Monto"
                placeholder="$0.00"
                type="number"
                :min="0"
                :max="9999999"
                suffix="MXN"
                prefix="$"
                @keypress="isNumberMoney($event)"
                hide-details=""
              >
              </v-text-field> -->
              <input-currency-global
                :flagModal="vDialogAdd"
                labelGlobal="Monto cobrado"
                @changeMontoGlobal="changeMontoGlobal"
                :modelValue="monto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="notas"
                label="Descripción del cobro(opcional)"
                placeholder="Escribir nota"
                maxlength="255"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div class="content-buttons">
        <v-btn
          x-large
          color="black"
          width="175"
          outlined
          class="text-Poppins"
          @click="closeDialog()"
          style="
            height: 50px;
            font-size: 13px;
            font-weight: 600;
            border-radius: 5px;
          "
        >
          Cancelar
        </v-btn>
        <v-btn
          @click="addCobro()"
          :disabled="!validateForm"
          :loading="bLoadingAddBtn"
          x-large
          width="175"
          color="primary"
          class="text-Poppins"
          style="
            height: 50px;
            color: black;
            box-shadow: 0px 20px 40px #00000029;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 600;
            margin-left: 50px;
          "
        >
          Registrar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseDialogComponent from "@/components/core/CloseDialog";
import InputCurrencyGlobal from "../../core/Input-Currency-Global.vue";

export default {
  name: "CobrosDialogAdd",
  props: {
    vDialogAdd: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      menu: false,
      fecha: null,
      concepto: "",
      metodoPago: "",
      monto: 0,
      notas: "",
      montoCopia: 0,
      items: [],
      bLoadingAddBtn: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$emit("toogleDialogAdd", !this.vDialogAdd);
      this.menu = false;
      this.fecha = null;
      this.concepto = "";
      this.monto = 0;
      this.montoCopia = 0;
      this.metodoPago = "";
      this.notas = "";
    },
    isNumberMoney(evt) {
      if (evt.target.value.includes(".")) {
        let sDecimal = "";
        sDecimal = evt.target.value.substring(
          evt.target.value.indexOf("."),
          evt.target.value.length
        );
        if (sDecimal.length > 2) {
          evt.preventDefault();
        }
      }
      if (evt.which === 101 || evt.which === 45) {
        evt.preventDefault();
      }
    },
    addCobro() {
      this.bLoadingAddBtn = true;
      const payload = {
          sProjectId: this.$route.params.sProyectId,
          sConcept: this.concepto,
          dQuantity: this.montoCopia,
          tChargeDate: this.fecha,
          sPaymentMethodId: this.metodoPago,
          sDescription: this.notas,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      //post credentials and get access token from laravel backend
      db.post(`${uri}/api/v1/charges`, payload, config)
        .then((response) => {
          this.$store.commit("refresher", true);
          this.concepto = "";
          this.montoCopia = 0;
          this.fecha = "";
          this.metodoPago = "";
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.bLoadingAddBtn = false;

          this.$store.commit("toggle_alert", alert);
          this.closeDialog();
        })
        .catch((err) => {
          this.bLoadingAddBtn = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.items = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changeMontoGlobal(value) {
      if (value !== "") {
        this.montoCopia = parseFloat(value).toFixed(2);
      } else {
        this.montoCopia = 0;
      }
    },
  },
  computed: {
    validateForm() {
    
      return (
        this.concepto.trim() !== "" &&
        (this.fecha !== "" && this.fecha !== null) &&
        (this.metodoPago !== "" && this.metodoPago !== null) &&
        parseFloat(this.montoCopia) 
      );
    },
  },
  watch: {
    vDialogAdd() {
      if (this.vDialogAdd) {
        this.getMetodoPago();
      }
    },
  },
  components: {
    CloseDialogComponent,
    InputCurrencyGlobal,
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  padding-bottom: 30px;
}

.separator {
  height: 1px;
  background-color: #ffce00 !important;
  border: none;
}

.rounded-dialog {
  border-radius: 10px !important;
}
</style>