<template>
  <div>
    <v-row>
      <!--#region CONTENT PROGRESS BAR -->
      <v-col cols="12">
        <div>
          <v-card style="padding: 15px; border-radius: 20px">
            <div style="display: flex; align-items: center; width: 100%">
              <p class="text-start texto-barra-porcentual mb-0">
                Gasto / Presupuesto
              </p>
              <v-spacer />
              <div style="width: 70%; display: flex; align-items: center">
                <v-progress-linear
                  :value="
                    resultadoBarraLineal(presupuestoInicial, presupuestoFinal)
                  "
                  buffer-value="100"
                  :color="
                    colorGP(
                      resultadoBarraLineal(
                        presupuestoInicial,
                        presupuestoFinal
                      ),
                      resultadoBarraLineal(
                        presupuestoInicialCobro,
                        presupuestoFinalCobro
                      )
                    )
                  "
                  style="
                    height: 20px;
                    width: 100%;
                    border-radius: 40px;
                    margin-top: 5px;
                  "
                >
                  <div>
                    ${{ mask(presupuestoFinal) }} MXN / ${{
                      mask(presupuestoInicial)
                    }}
                    MXN
                  </div>
                </v-progress-linear>
                <p class="ml-2 mb-0" style="margin-top: 5px">
                  {{
                    parseFloat(
                      resultadoBarraLineal(presupuestoInicial, presupuestoFinal)
                    ).toFixed(2)
                  }}%
                </p>
              </div>
            </div>

            <div
              class="mt-2"
              style="display: flex; align-items: center; width: 100%"
            >
              <p class="text-start texto-barra-porcentual mb-0">
                Cobros / Monto de contrato
              </p>
              <v-spacer />
              <div style="width: 70%; display: flex; align-items: center">
                <v-progress-linear
                  :value="
                    resultadoBarraLineal(
                      presupuestoInicialCobro,
                      presupuestoFinalCobro
                    )
                  "
                  buffer-value="100"
                  :color="
                    colorCC(
                      resultadoBarraLineal(
                        presupuestoInicial,
                        presupuestoFinal
                      ),
                      resultadoBarraLineal(
                        presupuestoInicialCobro,
                        presupuestoFinalCobro
                      )
                    )
                  "
                  style="
                    height: 20px;
                    width: 100%;
                    border-radius: 40px;
                    margin-top: 5px;
                  "
                >
                  <div>
                    ${{ mask(presupuestoFinalCobro) }} MXN / ${{
                      mask(presupuestoInicialCobro)
                    }}
                    MXN
                  </div>
                </v-progress-linear>
                <p class="ml-2 mb-0" style="margin-top: 5px">
                  {{
                    parseFloat(
                      resultadoBarraLineal(
                        presupuestoInicialCobro,
                        presupuestoFinalCobro
                      )
                    ).toFixed(2)
                  }}%
                </p>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <!--#endregion CONTENT PROGRESS BAR -->

      <!--#region CONTENT TAB DETAIL PROYECT -->
      <v-col cols="12">
        <v-tabs v-model="tabs" show-arrows>
          <v-tab class="global-tab" v-for="item in tabsItems" :key="item">
            {{ item }}
          </v-tab>
          <v-tabs-slider></v-tabs-slider>
        </v-tabs>

        <v-tabs-items v-model="tabs" class="mt-5">
          <!--#region tab 1: Presupuestos -->
          <v-tab-item>
            <v-card>
              <v-card-text class="text-end pa-1">
                <v-btn
                  class="text-Poppins Poppins"
                  color="primary"
                  style="
                    color: black;
                    border-radius: 5px;
                    font-size: 12px;
                    letter-spacing: 1px;
                    font-weight: 600;
                    
                  "
                  @click="openDialogAddOrEditCategory([], 0)"
                >
                  Añadir categoría
                </v-btn>
              </v-card-text>

              <v-card-text class="pa-1">
                <div style="margin-top: 15px">
                  <v-card style="padding: 15px; border-radius: 20px">
                    <!-- v-if="itemCategories.length !== 0" -->
                    <div>
                      <v-simple-table fixed-header height="320px">
                        <template v-slot:default>
                          <thead v-if="!bLoading">
                            <tr>
                              <th
                                class="text-left"
                                style="
                                  border-bottom: 3px solid #e4e4e4;
                                  width: 30% !important;
                                  min-width: 30% !important;
                                  max-width: 30% !important;
                                "
                              >
                                Gasto
                              </th>
                              <th
                                class="text-center"
                                style="
                                  border-bottom: 3px solid #e4e4e4;
                                  width: 40% !important;
                                  min-width: 35% !important;
                                  max-width: 40% !important;
                                "
                              >
                                Gasto / presupuesto
                              </th>
                              <th
                                class="text-start"
                                style="
                                  border-bottom: 3px solid #e4e4e4;
                                  width: 5% !important;
                                  min-width: 5% !important;
                                  max-width: 5% !important;
                                "
                              ></th>
                              <th
                                class="text-center"
                                style="
                                  border-bottom: 3px solid #e4e4e4;
                                  width: 20% !important;
                                  min-width: 20% !important;
                                  max-width: 20% !important;
                                "
                              >
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="!bLoading">
                            <tr
                              v-for="item in itemCategories"
                              :key="item.sCategoryId"
                            >
                              <td class="text-left">
                                {{ item.sCategoryName }}
                              </td>
                              <td
                                class="text-center"
                                style="white-space: nowrap"
                              >
                                <div v-if="item.sCategoryId != 'N/A'">
                                  <v-progress-linear
                                    @click="
                                      detailCategory(item.sCategoryId, item)
                                    "
                                    :value="item.percentCategoria"
                                    buffer-value="100"
                                    :color="item.colorCategoria"
                                    style="
                                      height: 20px;
                                      width: 100%;
                                      border-radius: 40px;
                                      margin-top: 5px;
                                      cursor: pointer;
                                    "
                                  >
                                    $ {{ mask(item.dSpent) }} MXN / $
                                    {{ mask(item.dBudget) }} MXN
                                  </v-progress-linear>
                                </div>

                                <div v-else>No Aplica</div>
                              </td>
                              <td>
                                <p
                                  v-if="item.sCategoryId != 'N/A'"
                                  class="ml-2"
                                  style="margin-top: 5px"
                                >
                                  {{ Number(item.percentCategoria) }}%
                                </p>
                              </td>
                              <td
                                class="text-center"
                                style="white-space: nowrap"
                              >
                                <v-btn
                                  v-if="bExpense"
                                  small
                                  dark
                                  :style="
                                    item.sCategoryId == 'N/A'
                                      ? 'background-color:withe'
                                      : 'background-color:grey'
                                  "
                                  :disabled="item.sCategoryId == 'N/A'"
                                  @click="
                                    viewDialogExpenseAdd(
                                      item.sCategoryId,
                                      false
                                    )
                                  "
                                  icon
                                >
                                  <v-icon
                                    size="20"
                                    :style="
                                      item.sCategoryId == 'N/A'
                                        ? 'visibility: hidden;'
                                        : ''
                                    "
                                  >
                                    mdi-currency-usd
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  @click="
                                    detailCategory(item.sCategoryId, item)
                                  "
                                  color="black"
                                  icon
                                >
                                  <v-icon> mdi-eye </v-icon>
                                </v-btn>
                                <v-btn
                                  :disabled="item.sCategoryId == 'N/A'"
                                  @click="deleteCategory(item)"
                                  icon
                                >
                                  <v-icon
                                    color="red"
                                    :style="
                                      item.sCategoryId == 'N/A'
                                        ? 'visibility: hidden;'
                                        : ''
                                    "
                                  >
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                            <tr v-show="bEmptyDataCategory">
                              <td colspan="4">
                                <div
                                  style="
                                    width: 100%;
                                    height: 200px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  "
                                >
                                  <p
                                    style="
                                      text-align: center;
                                      font-family: 'Poppins', sans-serif;
                                      font-weight: 600;
                                    "
                                  >
                                    Aún no se han agregado categorías <br />
                                    a este proyecto.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <div v-show="bLoading">
                            <v-progress-linear
                              indeterminate
                              color="yellow darken-2"
                            ></v-progress-linear>
                            <div class="content-text-loading">
                              <p class="text-loading">
                                Cargando... Espere por favor
                              </p>
                            </div>
                          </div>
                        </template>
                      </v-simple-table>
                      <hr
                        style="
                          height: 1px;
                          background-color: #efefef;
                          border: none;
                        "
                      />
                      <div class="footer-table">
                        <div style="margin-right: 30px">
                          <p>
                            Filas por página:
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="footer-table-dropdown"
                                  text
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ iItemsPerPage }}
                                  <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="changeTableContacts(5)">
                                  <v-list-item-title>5</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(10)">
                                  <v-list-item-title>10</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(25)">
                                  <v-list-item-title>25</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(50)">
                                  <v-list-item-title>50</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </p>
                        </div>
                        <div style="margin-right: 15px">
                          <p>
                            {{ iCurrentPage }}-{{ numPages }} de
                            {{ totalItems }}
                          </p>
                        </div>
                        <div>
                          <v-btn
                            elevation="0"
                            class="footer-table-buttons"
                            @click="backPageContacts"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            elevation="0"
                            class="footer-table-buttons"
                            @click="nextPageContacts"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--#endregion tab 1: Presupuestos -->

          <!--#region tab 2: Gastos -->
          <v-tab-item>
            <v-card>
              <v-card-text class="pa-1">
                <div style="margin-top: 15px">
                  <v-card style="padding: 15px; border-radius: 20px">
                    <div>
                      <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                          <thead v-if="!bLoading">
                            <tr class="headTable">
                              <th class="text-left">Tipo</th>
                              <th class="text-left">Fecha</th>
                              <th class="text-left">Concepto</th>
                              <th class="text-left">Proveedor</th>
                              <th class="text-left">Cantidad</th>
                              <th class="text-left">U.de medida</th>
                              <th class="text-left">Precio unitario</th>
                              <th class="text-left">Subtotal</th>
                              <th class="text-left">Método de pago</th>
                              <th class="text-center">Acciones</th>
                            </tr>
                          </thead>
                          <tbody v-if="!bLoading">
                            <tr
                              v-for="(item, index) in itemExpenses"
                              :key="index"
                            >
                              <td>{{ item.sType }}</td>
                              <td>
                                {{
                                  item.sType === "GM"
                                    ? item.tExpenseDate
                                    : item.tCreated
                                }}
                              </td>
                              <td>
                                {{
                                  item.sType == "OC"
                                    ? item.sPurchaseName.substr(0, 8)
                                    : item.sPurchaseName
                                }}
                              </td>
                              <td>
                                {{
                                  item.sSupplierName == null
                                    ? "N/A"
                                    : item.sSupplierName
                                }}
                              </td>
                              <td>{{ item.dQuantity }}</td>
                              <td>{{ item.sMeasureUnitName }}</td>
                              <td>
                                {{
                                  item.sType == "OC"
                                    ? "N/A"
                                    : "$" + mask(item.dUnitPrice) + "MXN"
                                }}
                              </td>
                              <td>${{ mask(item.dMaterialTotal) }} MXN</td>
                              <td>{{ item.sPaymentMethod }}</td>

                              <td style="white-space: nowrap">
                                <div class="d-flex" v-if="bExpense">
                                  <v-btn
                                    v-if="item.sType == 'GM'"
                                    @click="
                                      viewDialogExpenseEdit(
                                        item.sPurchaseOrderId,
                                        false
                                      )
                                    "
                                    icon
                                    color="black"
                                  >
                                    <v-icon> mdi-pencil </v-icon>
                                  </v-btn>
                                  <v-btn
                                    color="black"
                                    icon
                                    v-else
                                    @click="getDetailOC(item.sPurchaseOrderId)"
                                  >
                                    <v-icon> mdi-eye</v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="item.sType == 'GM'"
                                    @click="deleteExpense(item)"
                                    icon
                                    color="#F44336"
                                  >
                                    <v-icon> mdi-delete </v-icon>
                                  </v-btn>
                                </div>
                              </td>
                            </tr>
                            <tr v-show="bEmptyData">
                              <td colspan="10">
                                <div
                                  style="
                                    width: 100%;
                                    height: 200px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  "
                                >
                                  <p
                                    style="
                                      text-align: center;
                                      font-family: 'Poppins', sans-serif;
                                      font-weight: 600;
                                    "
                                  >
                                    No existen órdenes de compra o gastos
                                    manuales.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <div v-show="bLoading">
                            <v-progress-linear
                              indeterminate
                              color="yellow darken-2"
                            ></v-progress-linear>
                            <div class="content-text-loading">
                              <p class="text-loading">
                                Cargando... Espere por favor
                              </p>
                            </div>
                          </div>
                        </template>
                      </v-simple-table>
                      <hr
                        style="
                          height: 1px;
                          background-color: #efefef;
                          border: none;
                        "
                      />
                      <div class="footer-table">
                        <div style="margin-right: 30px">
                          <p>
                            Filas por página:
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="footer-table-dropdown"
                                  text
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ iItemsPerPage }}
                                  <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="changeTableContacts(5)">
                                  <v-list-item-title>5</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(10)">
                                  <v-list-item-title>10</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(25)">
                                  <v-list-item-title>25</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeTableContacts(50)">
                                  <v-list-item-title>50</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </p>
                        </div>
                        <div style="margin-right: 15px">
                          <p>
                            {{ iCurrentPage }}-{{ numPages }} de
                            {{ totalItems }}
                          </p>
                        </div>
                        <div>
                          <v-btn
                            elevation="0"
                            class="footer-table-buttons"
                            @click="backPageContacts"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            elevation="0"
                            class="footer-table-buttons"
                            @click="nextPageContacts"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--#endregion tab 2: Gastos -->

          <!--#region tab 3: Cobros -->
          <v-tab-item>
            <cobros-content />
          </v-tab-item>
          <!--#endregion tab 3: Cobros -->

          <!--#region tab 3: Información general  -->
          <v-tab-item>
            <v-card>
              <v-card-text class="pa-0">
                <div>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="nombreProyecto"
                          class="text-Poppins"
                          label="Nombre del proyecto"
                          style="margin-bottom: -10px"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col
                        cols="12"
                        sm="12"
                        md="4"
                      >
                        <v-select
                          v-model="residente"
                          class="text-Poppins"
                          item-text="sFullName"
                          item-value="sUserId"
                          :items="items"
                          label="Residente"
                          style="margin-bottom: -10px"
                          @change="changeResidente"
                        ></v-select>
                      </v-col> -->

                      <v-col cols="12" sm="12" md="4">
                        <v-select
                          v-model="estatus"
                          class="text-Poppins"
                          item-text="sName"
                          item-value="sProjectStatusId"
                          :items="itemsStatus"
                          label="Estatus"
                          style="margin-bottom: -10px"
                          @change="changeEstatus"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <input-currency-global
                          :flagModal="true"
                          :bDense="false"
                          labelGlobal="Monto de contrato"
                          @changeMontoGlobal="changeMontoGlobal"
                          :modelValue="montoContrato"
                        />
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <p
                          style="font-weight: 600; color: black; font-size: 18px"
                          class="text-Poppins"
                        >
                          Residentes
                        </p>
                        <v-card style="border-radius: 10px">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    class="text-left"
                                    style="
                                    max-width: 50%;
                                    min-width: 50%;
                                    width: 50%;
                                  "
                                  >
                                    Residente
                                  </th>
                                  <th
                                    class="text-left"
                                    style="
                                    max-width: 30%;
                                    min-width: 30%;
                                    width: 30%;
                                  "
                                  >
                                    Cargo
                                  </th>
                                  <th
                                    style="
                                    max-width: 20%;
                                    min-width: 20%;
                                    width: 20%;
                                  "
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in itemsResidents"
                                  :key="index"
                                >
                                  <td>{{ item.sNameResident }}</td>
                                  <td>
                                    {{ item.sResidentRoleName }}
                                  </td>
                                  <td>
                                    <v-icon
                                      v-if="permisionUser !== '2'"
                                      color="red"
                                      @click="deleteItemResident(index)"
                                    >
                                      mdi-close</v-icon
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <div
                            style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                          >
                            <hr
                              style="
                              width: 97%;
                              border: 1px solid #fafafa;
                            "
                            />
                          </div>
                          <div
                            v-if="permisionUser !== '2'"
                            style="border-bottom: 2px solid #e0e0e0 !important"
                          >
                            <v-form>
                              <v-container fluid>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-select
                                      v-model="sNameResident"
                                      clearable
                                      class="input-global"
                                      no-data-text="Sin información"
                                      :items="items"
                                      item-text="sFullName"
                                      item-value="sUserId"
                                      label="Seleccionar residente"
                                      dense
                                      solo
                                    >
                                    </v-select>
                                  </v-col>

                                  <v-col cols="12" md="4">
                                    <v-select
                                      v-model="sResidentRoleId"
                                      clearable
                                      class="input-global"
                                      no-data-text="Sin información"
                                      :items="itemsResidentRole"
                                      item-text="sName"
                                      item-value="sResidentRoleId"
                                      label="Seleccionar cargo"
                                      dense
                                      solo
                                    >
                                    </v-select>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    md="2"
                                    class="d-flex align-center"
                                  >
                                    <v-btn
                                      style="
                                      background-color: #ffce00;
                                      font-family: 'Poppins', sans-serif;
                                      font-size: 12px;
                                      font-weight: 600;
                                      border-radius: 10px;
                                    "
                                      :disabled="!validateAnadirResident"
                                      @click="setResident"
                                      >Añadir
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

                <div class="text-center" style="padding-top: 60px">
                  <v-btn
                    x-large
                    color="black"
                    width="175"
                    outlined
                    class="text-Poppins"
                    @click="close"
                    style="
                      height: 50px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                    "
                    >Cancelar
                  </v-btn>
                  <v-btn
                    v-if="
                      aAllows.includes('Projects') ||
                        aAllows.includes('Administrator')
                    "
                    x-large
                    width="175"
                    color="primary"
                    class="text-Poppins"
                    style="
                      height: 50px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                      margin-left: 50px;
                    "
                    :disabled="!validateForm"
                    @click="updateItems"
                    >Guardar
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--#endregion tab 3: Información general  -->
        </v-tabs-items>
      </v-col>
      <!--#endregion CONTENT TAB DETAIL PROYECT -->
    </v-row>

    <v-dialog
      persistent
      class="modalNuevoProyecto"
      v-model="dialogAddOrEditCategory"
      width="500"
      style="z-index: 999999999999"
    >
      <v-card
        style="border-radius: 10px; padding-top: 15px; padding-bottom: 15px"
      >
        <v-icon
          @click="closeDialogAddOrEdit()"
          color="#000000"
          style="position: absolute; right: 25px"
          >mdi-close
        </v-icon>

        <v-card-title
          class="headline justify-center"
          style="text-align: center"
        >
          {{
            bAddOrEditCategory == 0 ? "Añadir categoria" : "Modificar categoría"
          }}
        </v-card-title>
        <hr style="height: 1px; background-color: #ffce00; border: none" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nombreCategoria"
                  class="text-Poppins"
                  label="Nombre de la categoría"
                  placeholder="Nombre de la categoría"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <input-currency-global
                  :flagModal="dialogAddOrEditCategory"
                  :bDense="false"
                  @changeMontoGlobal="changeMontoCategoriaGlobal"
                  :modelValue="montoCategoria"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-layout style="height: 50px !important" justify-center>
          <v-card-actions justify-center class="pa-6">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn
                    x-large
                    color="black"
                    width="175"
                    outlined
                    class="text-Poppins"
                    @click="closeDialogAddOrEdit()"
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                    "
                    >Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn
                    x-large
                    width="175"
                    color="primary"
                    class="text-Poppins"
                    style="
                      height: 40px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                    "
                    @click="
                      bAddOrEditCategory == 0 ? addCategory() : editCategory()
                    "
                    :disabled="!validateFormCategoryes"
                    :loading="loadingButton"
                  >
                    {{ bAddOrEditCategory == 0 ? "Crear" : "Guardar" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>
    <view-dialog-order-global
      @close="openDialogOrderGlobal = false"
      :openDialogOrderGlobal="openDialogOrderGlobal"
      :itemDetails="itemConcepto"
    />
    <view-dialog-expense
      @close="openDialogExpense = false"
      :openDialogExpense="openDialogExpense"
      :sProjectId="projectId"
      :sCategoryId="categoryId"
      :sCustomExpenseId="sCustomExpenseId"
      :typeBloqued="btypeBloqued"
      :sAddorEditExpense="sAddorEditExpense"
      :bBlockedCategory="bBlockedCategory"
    />
  </div>
</template>

<script>
import ViewDialogExpense from "@/components/views/Proyects/DialogExpense";
import ViewDialogOrderGlobal from "@/components/core/DialogOrder";
import InputCurrencyGlobal from "../../core/Input-Currency-Global.vue";

import CobrosContent from "@/components/views/Proyects/CobrosContent";

export default {
  name: "DetailsProjects",
  props: {
    openDialogDetails: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  components: {
    ViewDialogExpense,
    ViewDialogOrderGlobal,
    CobrosContent,
    InputCurrencyGlobal,
  },
  data() {
    return {
      bLoading: true,
      bEmptyData: false,
      bEmptyDataCategory: false,
      openDialogExpense: false,
      dialogAddOrEditCategory: false,
      dialogDetailCategory: false,
      bAddOrEditCategory: null,
      projectId: "",

      sCustomExpenseId: "",
      categoryId: "",
      nombreCategoria: "",
      montoCategoria: 0,
      montocategoriaBarra: "",
      spentByCategory: "",
      percentCategory: "",
      colorCategory: "",

      nombreProyecto: "",
      nombreProyectoOrigin: "",
      btypeBloqued: 2,
      loadingButton: false,
      bModifyCategory: false,
      bBlockedCategory: false,
      sAddorEditExpense: false, // false= Add , true= Edit
      residente: "",
      estatus: "",
      estatusOrigin: "",
      montoContrato: 0,
      montoContratoOrigin: 0,
      presupuestoInicial: "",
      presupuestoFinal: "",
      presupuestoInicialCobro: "",
      presupuestoFinalCobro: "",
      formatPresIni: 0,
      formatPresFin: 0,
      sNameResident: null,
      sResidentRoleId: null,

      items: [],
      itemsOrigin: [],
      itemsResidentRole: [],
      itemsStatus: [],
      itemOrders: [],
      itemCategories: [],
      itemOrdersByCategory: [],
      itemOrdersByCategoryDemo: [],
      itemExpenses: [],
      itemCategoryById: [],
      itemsResidents: [],
      itemsResidentsOrigin: [],

      itemConcepto: {},

      percent: 0,
      percentCharged: 0,
      permisionUser: localStorage.getItem("permisionUser"),
      aAllows: this.$store.state.aAllows.split(","),
      tabsItems: ["Presupuestos", "Gastos", "Cobros", "Información general"],
      tabs: null,

      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,

      iCurrentPageCategoryDetail: 1,
      iPageNumberCategoryDetail: 1,
      iItemsPerPageCategoryDetail: 50,
      numPagesCategoryDetail: 0,
      totalItemsCategoryDetail: 0,

      bGetCobro: false,
      openDialogOrderGlobal: false,
    };
  },
  beforeMount() {
    this.projectId = this.$route.params.sProyectId;
    this.categorySelected = "";
    this.getDetailProyect();
    this.getCategoryByProyect();
    this.getStatus();
  },
  methods: {
    isNumberMoney(evt) {
      if (evt.target.value.includes(".")) {
        let sDecimal = "";
        sDecimal = evt.target.value.substring(
          evt.target.value.indexOf("."),
          evt.target.value.length
        );
        if (sDecimal.length > 2) {
          evt.preventDefault();
        }
      }
      if (evt.which === 69 || evt.which === 101 || evt.which === 45) {
        evt.preventDefault();
      }
    },

    close() {
      this.$emit("close");
      this.tabs = null;
      this.iCurrentPage = 1;
      this.iPageNumber = 1;
      this.iItemsPerPage = 50;
      this.numPages = 0;
      this.totalItems = 0;

      this.nombreProyecto = "";
      this.residente = "";
      this.estatus = "";
      this.presupuestoInicial = "";
      this.presupuestoFinal = "";
      this.itemCategories = [];
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    colorPercent(percent) {
      percent = Number(percent);
      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },
    colorGP(GP, CC) {
      let indicador = Number(GP);
      if (indicador <= 25.99) {
        return "green";
      } else if (indicador >= 26.0 && indicador <= 50.99) {
        return "yellow";
      } else if (indicador >= 51.0 && indicador <= 75.99) {
        return "orange";
      } else if (indicador >= 76.0) {
        return "red";
      } else {
        return "grey";
      }
    },
    colorCC(GP, CC) {
      let indicador = Number(CC);
      if (indicador <= 25.99) {
        return "red";
      } else if (indicador >= 26.0 && indicador <= 50.99) {
        return "orange";
      } else if (indicador >= 51.0 && indicador <= 75.99) {
        return "yellow";
      } else if (indicador >= 76.0) {
        return "green";
      } else {
        return "grey";
      }
    },
    resultadoBarraLineal(inicial, final) {
      const total = Number(inicial),
        montoCalculado = Number(final);

      var res = 0;
      if (total > 0 && montoCalculado > 0) {
        var res = (montoCalculado * 100) / total;
      }
      return res;
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    resultadoBarraLinealPorCategoria(presupuestoInicial, presupuestoFinal) {
      const total = Number(presupuestoInicial),
        montoCalculado = Number(presupuestoFinal);
      var res = 0;
      if (presupuestoFinal > 1) {
        res = parseFloat((montoCalculado * 100) / total).toFixed(2);
      }
      // this.percent = res;
      return res;
    },
    colorPercentPorCategoria(percent) {
      percent = Number(percent);

      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },

    changeResidente(e) {
      this.residente = {
        sFullName: this.residente.sFullName,
        sUserId: e,
      };
    },
    changeEstatus(e) {
      this.estatus = {
        sName: this.estatus.sName,
        sProjectStatusId: e,
      };
    },

    // get GENERAL INFORMATION
    getDetailProyect() {
      if (this.projectId) {
        db.get(`${uri}/api/v1/projects/${this.projectId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.nombreProyecto = resp.data.project.sName;
            this.nombreProyectoOrigin = resp.data.project.sName;
            this.residente = {
              sFullName: resp.data.project.sNameResident,
              sUserId: resp.data.project.sUserId,
            };
            this.itemsResidents = resp.data.project.ProjectResidents.map(
              (e) => {
                return {
                  sNameResident: e.sResidentName,
                  sUserId: e.sResidentId,
                  sResidentRoleName: e.sResidentRole,
                  sResidentRoleId: e.sResidentRoleId,
                };
              }
            );
            this.itemsResidentsOrigin = resp.data.project.ProjectResidents.map(
              (e) => {
                return {
                  sNameResident: e.sResidentName,
                  sUserId: e.sResidentId,
                  sResidentRoleName: e.sResidentRole,
                  sResidentRoleId: e.sResidentRoleId,
                };
              }
            );
            this.estatus = {
              sName: resp.data.project.ProjectStatusRecords.sName,
              sProjectStatusId:
                resp.data.project.ProjectStatusRecords.sProjectStatusId,
            };
            this.estatusOrigin = {
              sName: resp.data.project.ProjectStatusRecords.sName,
              sProjectStatusId:
                resp.data.project.ProjectStatusRecords.sProjectStatusId,
            };
            this.montoContrato = parseFloat(resp.data.project.dContractAmount);
            this.montoContratoOrigin = parseFloat(
              resp.data.project.dContractAmount
            );
            this.presupuestoInicial = resp.data.project.dBudget;
            this.presupuestoFinal = resp.data.project.dSpent;
            this.presupuestoInicialCobro = resp.data.project.dContractAmount;
            this.presupuestoFinalCobro = resp.data.project.dAmountCharged;

            this.resultadoBarraLineal();
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },

    // get GASTOS
    getExpensesByProyect() {
      // this.bLoading = false;
      // this.bEmptyData = this.itemOrdersByCategoryDemo.length === 0;
      this.bEmptyData = false;

      db.get(
        `${uri}/api/v1/purchase_order/project/${this.projectId}/expenses`,
        {
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.totalItems = resp.data.iTotal;
          this.itemExpenses = resp.data.orders;
          this.bLoading = false;
          this.bEmptyData = this.itemExpenses.length === 0;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
      // if (this.projectId) {
      //   db.get(`${uri}/api/v1/purchase_order/project/${this.projectId}`, {
      //     params: {
      //       iPageNumber: this.iCurrentPage,
      //       iItemsPerPage: this.iItemsPerPage,
      //     },
      //     headers: {
      //       Authorization: "Bearer " + localStorage.getItem("user-tk"),
      //       "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      //     },
      //   })
      //     .then((resp) => {
      //       this.numPages = resp.data.iNumPages;
      //       this.iPageNumber = resp.data.iNumPages;
      //       this.totalItems = resp.data.iTotal;
      //       this.itemOrders = resp.data.orders;
      //       this.bLoading = false;
      //       this.bEmptyData = this.itemOrders.length === 0;
      //     })
      //     .catch((err) => {
      //       this.bLoading = false;
      //       const alert = {
      //         color: "red",
      //         text: err.response.data.message,
      //       };
      //       this.$store.commit("toggle_alert", alert);
      //     });
      // }
    },
    // get category by proyect
    getCategoryByProyect() {
      this.bLoading = true;
      this.bEmptyDataCategory = false;

      this.itemCategories = [];
      db.get(`${uri}/api/v1/categories/`, {
        params: {
          sProjectId: this.projectId,
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.totalItems = resp.data.iTotal;
          this.itemCategories = resp.data.categories.map((e) => {
            return {
              ...e,
              percentCategoria: this.resultadoBarraLinealPorCategoria(
                e.dBudget,
                e.dSpent
              ),
              colorCategoria: this.colorPercentPorCategoria(
                this.resultadoBarraLinealPorCategoria(e.dBudget, e.dSpent)
              ),
            };
          });

          this.resultadoBarraLineal();
          this.bLoading = false;
          this.bEmptyDataCategory = this.itemCategories.length === 0;

          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    // get category detail
    getCategoryDetail() {
      if (this.categoryId == "N/A") {
        db.get(`${uri}/api/v1/categories/inactive/${this.projectId}`, {
          params: {
            iPageNumber: this.iCurrentPageCategoryDetail,
            iItemsPerPage: this.iItemsPerPageCategoryDetail,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.numPagesCategoryDetail = resp.data.iNumPages;
            this.iPageNumberCategoryDetail = resp.data.iNumPages;
            this.totalItemsCategoryDetail = resp.data.iTotal;
            this.itemOrdersByCategory = resp.data.orders;
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        db.get(`${uri}/api/v1/purchase_order/orders/${this.categoryId}`, {
          params: {
            iPageNumber: this.iCurrentPageCategoryDetail,
            iItemsPerPage: this.iItemsPerPageCategoryDetail,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((resp) => {
            this.numPagesCategoryDetail = resp.data.iNumPages;
            this.iPageNumberCategoryDetail = resp.data.iNumPages;
            this.totalItemsCategoryDetail = resp.data.iTotal;
            this.itemOrdersByCategory = resp.data.orders;
            this.$store.commit("refresher", false);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },

    updateItems() {
      // update project
      if (
        this.nombreProyecto !== "" &&
        this.presupuestoInicial !== "" &&
        this.montoContrato !== 0 &&
        this.estatus !== ""
      ) {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
          },
        };
        const params = {
          sName: this.nombreProyecto,
          dBudget: this.presupuestoInicial,
          dContractAmount: this.montoContrato,
          sProjectStatusId: this.estatus.sProjectStatusId,
          aUsers: this.itemsResidents.map((e) => {
            return {
              sUserId: e.sUserId,
              sResidentRoleId: e.sResidentRoleId,
            };
          }),
        };

        db.put(`${uri}/api/v1/projects/${this.projectId}`, params, config)
          .then((resp) => {
            const alert = {
              color: "green",
              text: resp.data.message,
            };
            this.$store.commit("toggle_alert", alert);
            this.$store.commit("refresher", true);
          })
          .catch((err) => {
            this.bLoading = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        const alert = {
          color: "red",
          text: "El proyecto no se puede actualizar con los campos vacios.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    getUsers() {
      // get users
      db.get(`${uri}/api/v1/users?iPageNumber=1`, {
        params: {
          sUserType: 1,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.items = resp.data.users;

          this.itemsOrigin = resp.data.users;

          this.setRemoveResidentToList();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPosition() {
      db.get(`${uri}/api/v1/resident-roles/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsResidentRole = resp.data.roles;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getStatus() {
      // get users
      db.get(`${uri}/api/v1/project_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsStatus = resp.data.status;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    openDialogAddOrEditCategory(item, optionDialog) {
      this.dialogAddOrEditCategory = true;
      this.bAddOrEditCategory = optionDialog;
      if (optionDialog == 1) {
        this.categoryId = item.sCategoryId;
        this.nombreCategoria = item.sCategoryName;
        this.montoCategoria = parseFloat(item.dBudget);
      }
    },
    detailCategory(categoryId, item) {
      // this.$router.push("/admin/proyects/"+ this.projectId +"/category/" + ((categoryId == "N/A")? '0' : categoryId) );
      this.$router.push({
        name: "Category",
        params: {
          sProjectId: this.projectId,
          sCategorytId: categoryId,
          nameCategory: item.sCategoryName,
          spent: item.dSpent,
          budget: item.dBudget,
        },
      });
    },

    closeDialogAddOrEdit() {
      this.dialogAddOrEditCategory = false;
      this.bAddOrEditCategory = null;
      this.nombreCategoria = "";
      this.montoCategoria = 0;
    },

    addCategory() {
      this.loadingButton = true;
      const payload = {
        sProjectId: this.projectId,
        sName: this.nombreCategoria,
        dBudget: this.montoCategoria,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      db.post(`${uri}/api/v1/categories/`, payload, config)
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.closeDialogAddOrEdit();
          this.$store.commit("toggle_alert", alert);
          this.loadingButton = false;

          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.loadingButton = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    editCategory() {
      this.loadingButton = true;

      const payload = {
        sName: this.nombreCategoria,
        dBudget: this.montoCategoria,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      db.put(`${uri}/api/v1/categories/${this.categoryId}`, payload, config)
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.bModifyCategory = false;
          this.$store.commit("toggle_alert", alert);
          this.loadingButton = false;

          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.loadingButton = false;

          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    deleteCategory(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sCategoryId,
        route: "/api/v1/categories/" + item.sCategoryId,
        unit: " categoría.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
      // this.$store.commit("refresher", true);
    },

    changeTableContacts(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      switch (this.tabs) {
        case 0:
          this.getCategoryByProyect();
          break;
        case 1:
          this.getExpensesByProyect();
          break;
        case 2:
          this.$store.commit("setTabProyectDetail", 2);
          break;
        case 3:
          this.getDetailProyect();
          break;
        default:
          break;
      }
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },

    changeTableCategoryDetail(e) {
      this.iItemsPerPageCategoryDetail = e;
      this.iCurrentPageCategoryDetail = 1;
      this.getCategoryDetail();
    },
    backPageCategoryDetail() {
      if (this.iCurrentPageCategoryDetail > 1) {
        this.iCurrentPageCategoryDetail--;
      }
    },
    nextPageCategoryDetail() {
      if (this.iCurrentPageCategoryDetail < this.numPagesCategoryDetail) {
        this.iCurrentPageCategoryDetail++;
      }
    },
    colorEstatus(estatus) {
      let color = "";
      switch (estatus) {
        case "Borrador":
          color = "#7e7e7e";
          break;

        case "Aprobada":
          color = "#97e6b6";
          break;

        case "Recibida":
          color = "#97e6b6";
          break;

        case "Correcciones":
          color = "#ff7d7d";
          break;

        case "Pagada":
          color = "#ffce00";
          break;

        case "Cancelada":
          color = "#ff7d7d";
          break;

        default:
          break;
      }

      return color;
    },
    viewDialogExpenseAdd(sCategoryId, bBlockedCategory) {
      this.sAddorEditExpense = false; // false= Add
      this.categoryId = sCategoryId;
      this.bBlockedCategory = bBlockedCategory;
      this.sCustomExpenseId = "";
      this.openDialogExpense = true;
    },
    viewDialogExpenseEdit(sCustomExpenseId) {
      this.openDialogExpense = true;
      this.sAddorEditExpense = true; // tue= edit

      this.sCustomExpenseId = sCustomExpenseId;
    },
    deleteExpense(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/custom_expenses/" + item.sPurchaseOrderId,
        unit: " gasto.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
      // this.$store.commit("refresher", true);
    },
    viewDialogOrderGlobal(item) {
      this.openDialogOrderGlobal = true;
      this.itemConcepto = item;
    },
    getDetailOC(sOCId) {
      this.$router.push({
        name: "ProyectsDetailOC",
        params: { sOCId: sOCId },
      });
    },
    changeMontoGlobal(value) {
      if (value !== "") {
        this.montoContrato = parseFloat(value);
      } else {
        this.montoContrato = 0;
      }
    },
    changeMontoCategoriaGlobal(value) {
      if (value === "") {
        this.montoCategoria = 0;
      } else {
        this.montoCategoria = parseFloat(value);
      }
    },
    deleteItemResident(e) {
      this.itemsResidents.splice(e, 1);
      this.setCheckResidentsValidation();
      this.setRemoveResidentToList();
    },
    setResident() {
      // const arr = {
      //   sNameResident: this.items.filter((e) => e.sUserId === this.sNameResident)[0].sFullName,
      //   sUserId: this.items.filter((e) => e.sUserId === this.sNameResident)[0].sUserId,
      //   sResidentRoleId: this.sResidentRoleId,
      // };
      const arr = {
        sNameResident: this.items.filter(
          (e) => e.sUserId === this.sNameResident
        )[0].sFullName,
        sUserId: this.items.filter((e) => e.sUserId === this.sNameResident)[0]
          .sUserId,
        sResidentRoleName: this.itemsResidentRole.filter(
          (e) => e.sResidentRoleId === this.sResidentRoleId
        )[0].sName,
        sResidentRoleId: this.itemsResidentRole.filter(
          (e) => e.sResidentRoleId === this.sResidentRoleId
        )[0].sResidentRoleId,
      };
      this.itemsResidents.push(arr);
      this.clearItemsResident();
      this.setRemoveResidentToList();
      this.setCheckResidentsValidation();
    },
    clearItemsResident() {
      this.sNameResident = null;
      this.sResidentRoleId = null;
    },
    setRemoveResidentToList() {
      this.items = this.itemsOrigin;
      for (let i = 0; i < this.itemsResidents.length; i++) {
        const elementResident = this.itemsResidents[i];
        this.items = this.items.filter(
          (e) => e.sUserId !== elementResident.sUserId
        );
      }
    },
    setCheckResidentsValidation() {
      let bModify = false;
      for (let i = 0; i < this.itemsResidents.length; i++) {
        const element = this.itemsResidents[i];
        const result =
          this.itemsResidentsOrigin.filter((e) => e.sUserId === element.sUserId)
            .length > 0;
        if (
          !result ||
          this.itemsResidents.length !== this.itemsResidentsOrigin.length
        ) {
          bModify = true;
          break;
        }
      }
      return bModify;
    },
  },
  computed: {
    refresh: function() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
    validateForm() {
      return (
        (this.nombreProyecto !== "" &&
          this.nombreProyecto !== this.nombreProyectoOrigin) ||
        (this.estatus.sProjectStatusId !== "" &&
          this.estatus.sProjectStatusId !==
            this.estatusOrigin.sProjectStatusId) ||
        (this.montoContrato !== 0 &&
          this.montoContrato !== this.montoContratoOrigin) ||
        this.setCheckResidentsValidation()
      );
    },
    validateFormCategoryes() {
      return (
        this.nombreCategoria.trim() !== "" &&
        this.montoCategoria !== "" &&
        this.montoCategoria >= 1
      );
    },
    bExpense() {
      return !this.$store.state.permissions.includes("Administrator")
        ? this.$store.state.permissions.includes("ExpenseManagement")
        : true;
    },
    validateAnadirResident() {
      return this.sNameResident !== null && this.sResidentRoleId !== null;
    },
    // permisionUser() {
    //   return this.$store.state.permisionUser;
    // }
  },
  watch: {
    iCurrentPage: function() {
      switch (this.tabs) {
        case 0:
          this.getCategoryByProyect();
          break;
        case 1:
          this.getExpensesByProyect();
          break;
        case 2:
          this.$store.commit("setTabProyectDetail", 2);
          break;
        case 3:
          this.getDetailProyect();
          break;
        default:
          break;
      }
    },
    iCurrentPageCategoryDetail: function() {
      this.getCategoryDetail();
    },
    refresh: function() {
      this.getDetailProyect();
      this.getCategoryByProyect();
      this.getExpensesByProyect();
      // this.getCategoryDetail();
      // this.getOrdersByProyect();
    },
    tabs: function() {
      this.bLoading = true;
      this.bEmptyData = false;
      this.iCurrentPage = 1;
      this.iPageNumber = 1;
      this.iItemsPerPage = 50;
      this.$store.commit("setTabProyectDetail", 0);
      switch (this.tabs) {
        case 0:
          this.getCategoryByProyect();
          break;
        case 1:
          this.getExpensesByProyect();
          break;
        case 2:
          this.$store.commit("setTabProyectDetail", 2);
          break;
        case 3:
          this.getDetailProyect();
          this.getUsers();
          this.getPosition();

          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center !important;
  margin-left: 5px !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.btn-sincategorizar[disabled] {
  color: transparent;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.v-tabs-slider-wrapper {
  height: 6px !important;
  border-radius: 10px !important;
  border: solid 1px white;
  border-bottom-color: #ffce00;
  width: auto;
}

.v-slide-group__wrapper {
  border: solid 1px white;
  border-bottom-color: #c4c4c4;
  width: auto;
}
</style>
<style scoped>
.text-progressbar {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 12px;
}

.texto-barra-porcentual {
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
}

.global-tab {
  font-family: "Poppins", sans-serif !important;
  color: black;
  /* letter-spacing: inherit !important; */
  text-transform: none !important;
  width: 250px !important;
  font-size: 15px !important;
  letter-spacing: -0px !important;
  font-weight: bolder;
}
</style>
